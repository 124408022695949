import { createGlobalStyle } from "styled-components"

import helveticaneueWoff from "./helvetica-neue.woff"
import helveticaneueWoff2 from "./helvetica-neue.woff2"
import helveticaneueLightWoff from "./helvetica-neue-light.woff"
import helveticaneueLightWoff2 from "./helvetica-neue-light.woff2"
import helveticaneueBoldWoff from "./helvetica-neue-bold.woff"
import helveticaneueBoldWoff2 from "./helvetica-neue-bold.woff2"
import lifeWoff from "./life.woff"
import lifeWoff2 from "./life.woff2"
import lifeEot from "./life.eot"
import lifeSvg from "./life.svg"
import lifeTtf from "./life.ttf"
import lifeBoldWoff from "./life-bold.woff"
import lifeBoldWoff2 from "./life-bold.woff2"
import lifeBoldEot from "./life-bold.eot"
import lifeBoldSvg from "./life-bold.svg"
import lifeBoldTtf from "./life-bold.ttf"

export const GlobalFonts = createGlobalStyle`
  @font-face {
    font-family: 'helveticaneue';
    font-display: auto;
    src:
      url('${helveticaneueLightWoff2}') format('woff2'),
      url('${helveticaneueLightWoff}') format('woff');
    font-weight: 200;
    font-style: 200;
  }
  @font-face {
    font-family: 'helveticaneue';
    font-display: auto;
    src:
      url('${helveticaneueWoff2}') format('woff2'),
      url('${helveticaneueWoff}') format('woff');
    font-weight: 400;
    font-style: 400;
  }
  @font-face {
    font-family: 'helveticaneue';
    font-display: auto;
    src:
      url('${helveticaneueBoldWoff2}') format('woff2'),
      url('${helveticaneueBoldWoff}') format('woff');
    font-weight: 700;
    font-style: 700;
  }
  @font-face {
    font-family: 'life';
    font-display: auto;
    src: url('life.eot');
    src:
      url('${lifeEot}?#iefix') format('embedded-opentype'),
      url('${lifeWoff2}') format('woff2'),
      url('${lifeWoff}') format('woff'),
      url('${lifeTtf}') format('truetype'),
      url('${lifeSvg}#life') format('svg');
    font-weight: 400;
    font-style: 400;
  }
  
  @font-face {
    font-family: 'life';
    font-display: auto;
    src: url('life-bold.eot');
    src:
    url('${lifeBoldEot}?#iefix') format('embedded-opentype'),
    url('${lifeBoldWoff2}') format('woff2'),
    url('${lifeBoldWoff}') format('woff'),
    url('${lifeBoldTtf}') format('truetype'),
    url('${lifeBoldSvg}#life') format('svg');
    font-weight: 700;
    font-style: 700;
  }
`
export default {
  life: "life, \"Times New Roman\", Times, serif",
  helvetica: "helveticaneue, Helvetica, Arial, sans-serif"
}
